import React from "react"
import styled from "styled-components"

const ButtonStyle = styled.button`
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 384px;
  height: 50px;
  left: 0px;
  top: 226px;
  background: #00ca42;
  border-radius: 60px;
  flex: none;
  align-self: center;
  flex-grow: 0;
  margin: 16px 0px;
  border: none;
  cursor: pointer;

  :hover {
    background: rgb(50, 205, 50);
  }
  :focus {
    background: rgb(50, 205, 50);
    border: none;
    outline: none;
  }

  :target {
    background: rgb(50, 205, 50);
    border: none;
    outline: none;
  }

  @media (max-width: 560px) {
    width: 209px;
  }
`
function Button({ children, action, disabled }) {
  return (
    <ButtonStyle autofocus disabled={disabled} onClick={action}>
      {children}
    </ButtonStyle>
  )
}

Button.defaultProps = {
  action: "",
  action: "",
}

export default Button
