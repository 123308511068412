import React from "react"
import styled from "styled-components"

const FormStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .alertContainer {
    min-height: 36px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .submit {
    font-family: "Work Sans";
    font-size: 18px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 50px;
    left: 0px;
    top: 226px;
    background: #00ca42;
    border-radius: 60px;
    flex: none;
    align-self: center;
    flex-grow: 0;
    margin: 16px 0px;
    border: none;
    cursor: pointer;
    &:hover {
      background: rgb(50, 205, 50);
    }
  }

  /* @media (max-width: 560px) {
    width: 209px;
  } */

  label {
    align-self: flex-start;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #111111;
    margin: 8px 0px;
    @media (max-width: 560px) {
      width: max-content;
    }
  }

  input {
    width: 384px;
    height: 51px;
    width: 100%;

    font-size: 16px;
    line-height: 19px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0px 20px;
    margin: 8px 0px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: black;
    ::placeholder {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #999999;
    }

    @media (max-width: 560px) {
      width: 300px;
      width: 100%;
    }
  }
`
function FormContainer({ children }) {
  return <FormStyle>{children}</FormStyle>
}

export default FormContainer
