import React from "react"
import styled from "styled-components"

const AlertStyle = styled.div`
  font-family: Work Sans;
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #e44331;
  margin: 0;
  padding: 0;
  margin-left: 0px;
  @media (max-width: 675px) {
    overflow: hidden;
    text-align: left;
  }
`
function Alert({ children }) {
  return <AlertStyle>{children}</AlertStyle>
}

export default Alert
