import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
const NameStyle = styled.h1`
  align-self: center;
  width: 100%;
  height: auto;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #111111;
  flex: none;
  flex-grow: 0;
  margin: 16px 0px;

  @media (max-width: 560px) {
    font-size: 30px;
  }
`
function PopupName({ children }) {
  return <NameStyle>{children}</NameStyle>
}

export default PopupName
