import React from "react"
import CardContainer from "./cardContainer"
import FormContainer from "./formContainer"
import PopupName from "./popupName"
import Button from "./button"
import Alert from "./alert"
import { useState, useEffect } from "react"
import styled from "styled-components"
import RegisterFailed from "./registerFailed"
import CheckMark from "../../icons/checkmark-icon.svg"
import Loader from "../../components/shared/loader"
import api from "../api"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import FormStyle from "./styles/formStyle"
import Eye from "../../icons/forms/eye.svg"
import EyeOff from "../../icons/forms/eyeOff.svg"

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 484px;
    margin: 0;

    div {
      display: flex;
      width: 100%;
      height: auto;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    label {
      align-self: flex-start;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #111111;
      margin: auto 0px;
      margin-right: 5%;
      white-space: nowrap;
      text-align: right;
      display: inline-block;

      width: 60%;

      @media (max-width: 600px) {
        text-align: left;
      }
    }
    .passInput {
      display: flex;
      flex-direction: row;
      width: 110%;
      @media (max-width: 600px) {
        width: 100%;
      }
      .vectorEye {
        width: 0;
      }

      svg {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        float: right;
        top: 16px;
        right: 40px;
        z-index: 100;
        cursor: pointer;
      }
    }
    .submit {
      font-family: "Work Sans";
      font-size: 18px;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      width: 100%;
      height: 50px;
      left: 0px;
      top: 226px;
      background: #00ca42;
      border-radius: 60px;
      flex: none;
      align-self: center;
      flex-grow: 0;
      margin: 16px 0px;
      border: none;
      cursor: pointer;
      &:hover {
        background: rgb(50, 205, 50);
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }

    input {
      width: 384px;
      height: 51px;
      width: 100%;

      font-size: 16px;
      line-height: 19px;
      border: 1px solid #d8d8d8;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0px 20px;
      margin: 8px 0px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: black;
      ::placeholder {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #999999;
      }

      @media (max-width: 560px) {
        width: 300px;
        width: 100%;
      }
    }

    .alertContainer {
      min-height: 36px;
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
`
const Requirements = styled.div`
  font-family: Work Sans;
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: gray;
  margin: 0;
  padding: 0;
  margin-left: 0px;
  @media (max-width: 675px) {
    overflow: hidden;
    text-align: left;
  }

  u {
    width: max-content;
    white-space: nowrap;
  }
`

const CapsLock = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  font-size: 12px;
  div {
    width: max-content !important;
  }
  b {
    width: max-content;
    ${props => (props.Caps ? `color: rgb(0, 202, 66);` : `color: red;`)}
  }
  margin-bottom: 8px;

  @media (max-width: 768px) {
    display: none !important;
  }
`
function Register() {
  const [key, setKey] = useState("")
  const [valid, setValid] = useState()
  const [success, setSuccess] = useState()
  // const [error, setError] = useState()
  const [initial, setIntial] = useState(true)
  const [registrationLoading, setRegistrationLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [passwordH, setPasswordH] = useState("")

  const [passVisible, setPassVisible] = useState("Password")
  const [repPassVisible, setRepPassVisible] = useState("Password")
  const [caps, setCaps] = useState(null)

  //On intial load form checks if request is valid or expired/used
  useEffect(() => {
    // GA4 Event
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "TrialVisitorReachedCreateAccountFlow",
      category: "TrialVisitorReachedCreateAccountFlow",
      action: "Try PDF Pro - Visitor reached finish setup page",
      label: "Try PDF Pro - Visitor reached finish setup page",
    })

    // GA4 Event - staging
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "GA4 - Custom Event",
      eventName: "Visitor Reached Create Account Flow",
      action:
        "User clicked on the link in the email and rechead the create account page",
    })
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    setKey(urlParams.get("key"))
    document.addEventListener("keyup", event => {
      if (event.getModifierState("CapsLock")) {
        setCaps(true)
      } else {
        setCaps(false)
      }
    })

    console.log("Send")
    api
      .get(`/api/v1/pdfpro/check-user-invite/?key=${urlParams.get("key")}`)
      .then(res => {
        // GA4 Event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "UserRegistered",
          category: "UserRegistered",
          action: "Try PDF Pro - User succesfully registered",
          label: "Try PDF Pro - User succesfully registered",
        })

        // GA4 Event - staging
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "GA4 - Custom Event",
          eventName: "Completed User Registration",
          action: "User has succesfully registered",
        })
        setValid(true)
        setIntial(false)
      })
      .catch(err => {
        setValid(false)
        setIntial(false)
      })
  }, [])

  /// defining useForm props

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = data => {
    console.log(data)

    if (data.RepeatPassword !== data.Password) {
      setError("RepeatPasswordM", {
        type: "NotMatching",
        message: "Passwords are not matching",
      })
    } else if (!/\d/.test(data.Password)) {
      setError("NumberPassword", {
        type: "NoNumber",
        message: "Password must contain one number",
      })

      if (data.Password.toLowerCase() == data.Password) {
        setError("UppercasePassword", {
          type: "NoUppercase",
          message: "Password must contain one uppercase letter",
        })
      }
    } else if (data.Password.toLowerCase() == data.Password) {
      setError("UppercasePassword", {
        type: "NoUppercase",
        message: "Password must contain one uppercase letter",
      })
    } else {
      api
        .post("/api/v1/pdfpro/trial-license/", {
          key: key,
          password: data.Password,
          product_code: "pdf",
          first_name: data.FirstName,
          last_name: data.LastName,
        })
        .then(res => {
          // GA4 Event
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "TrialEmailReceived",
            category: "TrialEmailReceived",
            action: "Try PDF Pro - Trial email delivered",
            label: "Try PDF Pro - Trial email delivered",
          })

          // GA4 Event - staging
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "GA4 - Custom Event",
            eventName: "Deliver Trial Invite Email",
            action: "Trial invite email is delivered to the recepient",
          })
          setSuccess(true)
          setRegistrationLoading(false)
        })
        .catch(err => {})
    }
  }

  return (
    <Center>
      {initial ? (
        <CardContainer>
          <Loader />
        </CardContainer>
      ) : valid ? (
        registrationLoading ? (
          <CardContainer>
            <Loader />
          </CardContainer>
        ) : !success ? (
          <CardContainer>
            <FormStyle>
              <form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
                <PopupName>Finish Setting up your account!</PopupName>
                <p
                  style={{
                    textAlign: `center`,
                    marginBottom: `32px`,
                    marginTop: `16px`,
                  }}
                >
                  Please enter your name and set a password. You will be using
                  the same password to log into your online account and the PDF
                  Pro desktop application.
                </p>

                <CapsLock Caps={caps}>
                  <div>{`Caps Lock:`}&nbsp;</div>{" "}
                  <b>{caps != null && (caps ? "ON" : "OFF")}</b>
                </CapsLock>
                {/* <input
                  autocomplete="false"
                  name="hidden"
                  type="text"
                  style="display:none;"
                /> */}
                <div>
                  <label htmlFor="FirstName">First Name</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="FirstName"
                    autocomplete="off"
                    placeholder="Enter First Name"
                    {...register("FirstName", { required: true })}
                  />
                </div>
                <div>
                  <label htmlFor="LastName">Last Name</label>
                  <input
                    type="text"
                    id="LastName"
                    name="LastName"
                    autocomplete="false"
                    placeholder="Enter Last Name"
                    {...register("LastName", { required: true })}
                  />
                </div>
                <div>
                  <label htmlFor="Password">Password</label>
                  <div className="passInput">
                    <input
                      type={passVisible}
                      id="Password"
                      name="Password"
                      placeholder="Enter Password"
                      {...register("Password", {
                        required: "Repeat password required",
                        minLength: {
                          value: 6,
                          message:
                            "Pasword should contain at least 6 characters",
                        },
                        onChange: e => {
                          setPasswordH(pas => (pas = e.target.value))
                          if (/\d/.test(e.target.value)) {
                            clearErrors("NumberPassword")
                          }
                          if (e.target.value.toLowerCase() != e.target.value) {
                            clearErrors("UppercasePassword")
                          }
                        },
                      })}
                    />
                    <div
                      className="vectorEye"
                      onClick={() =>
                        setPassVisible(vis =>
                          vis == "Text" ? "Password" : "Text"
                        )
                      }
                    >
                      {passVisible == "Password" && <EyeOff />}
                      {passVisible != "Password" && <Eye />}
                    </div>
                  </div>
                </div>
                <div>
                  <label htmlFor="Repeat Password">Repeat Password</label>
                  <div className="passInput">
                    <input
                      type={repPassVisible}
                      id="RepeatPassword"
                      name="RepeatPassword"
                      placeholder="Repeat Password"
                      {...register("RepeatPassword", {
                        required: {
                          value: true,
                          message: "Repeat password can not be empty",
                        },
                        onChange: e => {
                          if (e.target.value == passwordH) {
                            clearErrors("RepeatPasswordM")
                          }
                        },
                      })}
                    />
                    <div
                      className="vectorEye"
                      onClick={() =>
                        setRepPassVisible(vis =>
                          vis == "Text" ? "Password" : "Text"
                        )
                      }
                    >
                      {repPassVisible == "Password" && <EyeOff />}
                      {repPassVisible != "Password" && <Eye />}
                    </div>
                  </div>
                </div>
                <Requirements>
                  Password should containt 6 or more characters and contain at
                  least 1 number and 1 uppercase.
                </Requirements>
                <input
                  className="submit"
                  type="submit"
                  value="Complete Registration"
                />
                {/* Prints all errors */}
                <div className="alertContainer">
                  {Object.values(errors).map(prop => {
                    return <Alert>{prop.message}</Alert>
                  })}
                </div>

                {errors && console.log(errors)}
              </form>
            </FormStyle>
          </CardContainer>
        ) : (
          <CardContainer>
            <FormContainer>
              <PopupName>Registration finished successfully</PopupName>
              <p style={{ textAlign: `center`, marginTop: `26px` }}>
                Thank you!
              </p>
              <CheckMark />
              <Button
                disabled={isDisabled}
                type="button"
                action={() => navigate("/login")}
                style={{ marginTop: `26px` }}
              >
                Go to login
              </Button>
            </FormContainer>
          </CardContainer>
        )
      ) : (
        <RegisterFailed />
      )}
    </Center>
  )
}

export default Register
