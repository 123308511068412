import React from "react"
import CardContainer from "./cardContainer"
import FormContainer from "./formContainer"
import PopupName from "./popupName"
import Button from "./button"
import { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import Exclamination from "../../icons/exclamation.svg"
import TryPdf from "../shared/TryPdf"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"

const Center = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

function RegisterFailed() {
  const [key, setKey] = useState("")

  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    setKey(urlParams.get("key"))
  }, [])

  return (
    <Center>
      <CardContainer>
        <FormContainer>
          <PopupName>Something went wrong</PopupName>
          <Exclamination />
          <p style={{ textAlign: `center`, marginTop: `26px` }}>
            Your invite doesn't exist or is expired, send request again
          </p>
        </FormContainer>

        <Button action={openPopup}>Resend Trial request</Button>
      </CardContainer>
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </Center>
  )
}

export default RegisterFailed
