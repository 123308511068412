import React from "react"
import styled from "styled-components"

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 500px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 16px 0;
  padding: 32px;

  @media (max-width: 400px) {
    width: 90%;
  }
`
function CardContainer({ children }) {
  return <ContainerStyle>{children}</ContainerStyle>
}

export default CardContainer
